import Qs from 'qs';
import { httpClient } from './httpClient';

export const request = {
  get: (url, data) => {
    return httpClient.get(url, {
      params: data,
    });
  },
  getJson: (url, data) => {
    return httpClient.get(url, {
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  getXml: (url, data) => {
    return httpClient.get(url, {
      params: data,
      headers: {
        'Content-Type': 'application/xml',
      },
    });
  },
  post: (url, data) => {
    return httpClient.post(url, data);
  },
  postNew: (url, data) => {
    return httpClient.post(url, data);
  },
  postFormData: (url, data) => {
    return httpClient.post(url, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  postForm: (url, data) => {
    const reqData = Qs.stringify(data);
    return httpClient.post(url, reqData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  deleteMethod: (url, data) => {
    return httpClient.delete(url, {
      params: data,
    });
  },
  deleteMethodNew: (url, data) => {
    return httpClient.delete(url, {
      data: data,
    });
  },
  putMethod: (url, data) => {
    const reqData = Qs.stringify(data);
    return httpClient.put(url, reqData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  putNew: (url, data) => {
    return httpClient.put(url, data);
  },
};
