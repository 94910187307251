<template>
  <div
    class="Document"
    :style="{ fontSize: fontSize + 'px' }"
    v-html="htmlData"
  />
</template>

<script>
import md from '@/utils/markdown'

export default {
  name: 'MarkdownRender',
  props: {
    markdownData: {
      type: String,
      required: true,
    },
    fontSize: {
      type: Number,
      default: 14,
    },
  },
  emits: ['change'],
  computed: {
    htmlData() {
      const regExp = new RegExp(/\n/, 'g')
      return md.render(this.markdownData).replace(regExp, '')
    },
  },
  watch: {
    htmlData() {
      this.$nextTick(() => this.$emit('change'))
    },
  },
}
</script>

<style lang="less">
@import '../styles/document';
</style>
