import { request } from './request';
const workspaceId = '1268092697691975682'

export default {
    //搜阅搜索
    sooyue: {
        search(content = '') {
            return request.get('/api/giant-gateway/outer/mcs/search', {
                workspaceId: workspaceId,
                content: content,
                start: 0,
                rows: 10
            })
        },
        getContent(id, path) {
            return request.get(`/api/giant-gateway/outer/book/${id}/content/${path}`)
        }
    }

}