import MarkdownIt from 'markdown-it';
// import anchor from 'markdown-it-anchor';
import imsize from 'markdown-it-imsize';
import math from 'markdown-it-math';
// import footnote from 'markdown-it-footnote';
// import container from 'markdown-it-container';
// import toc from 'markdown-it-table-of-contents';
// import uslug from 'uslug'

// import hljs from 'highlight.js/lib/highlight';
// import 'highlight.js/styles/github.css';

// const usluglify = s => uslug(s);

const md = new MarkdownIt({
  html: true,
  xhtmlOut: false,
  // langPrefix: 'language-',
  linkify: true,
  typographer: true,
  /*
  highlight: function(str, lang) {
    if (lang) {
      try {
        const langModule = require(`highlight.js/lib/languages/${lang}`);
        hljs.registerLanguage(lang, langModule);
        console.log('markdown-it language:', lang, hljs.getLanguage(lang));
        return `<pre class="hljs"><code> ${hljs.highlight(lang, str).value} </code></pre>`;
      } catch (error) {
        console.log('markdown-it -> highlight.js:', error);
      }
    }

    return `<pre class="hljs"><code> ${md.utils.escapeHtml(str)} </code></pre>`;
  }
   */
});

// md.use(anchor, {
//   // permalink: true,
//   slugify: usluglify
// });

md.use(imsize);

md.use(math);

// md.use(container);

// md.use(toc);

// md.use(footnote);

export default md;
